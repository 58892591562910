import { useEffect, useState } from 'react'
import { requestOxomiMetaData } from '@obeta/utils/lib/requestOxomiMetaData'
import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { OxomiGlobal } from '@obeta/models/lib/models/Oxomi'
import { datadogRum } from '@datadog/browser-rum'
import { searchByOxomiInfoPlayObject } from '@obeta/utils/lib/search/searchByOxomiInfoPlayObject'
import { useTranslation } from 'react-i18next'

declare global {
  interface Window {
    oxomi: OxomiGlobal
    oxomi_ready: VoidFunction
  }
}
const OXOMI_SCRIPT_URL = 'https://oxomi.com/assets/frontend/v2/oxomi.js'

let isOxomiScriptStartLoading = false

const injectOxomiScript = () => {
  if (!isOxomiScriptStartLoading) {
    isOxomiScriptStartLoading = true
    fetch(OXOMI_SCRIPT_URL)
      .then((response) => response.text())
      .then((scriptContent) => {
        if (!document.querySelector('script[data-oxomi-script="true"]')) {
          const modifiedScriptContent = scriptContent.replace(/http:/g, 'https:')

          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.textContent = modifiedScriptContent
          script.async = true
          script.setAttribute('data-oxomi-script', 'true')
          document.getElementsByTagName('head')[0].appendChild(script)
        }
      })
      .catch((error) => {
        isOxomiScriptStartLoading = false
        console.error('Failed to load or modify Oxomi script:', error)
        datadogRum.addError(`injectOxomiScript error: ${error}`)
      })
      .finally(() => {
        isOxomiScriptStartLoading = false
      })
  }
}

type UseOxomiIntegrationOptions = {
  documentId: string
}
export const useOxomiIntegration = (options?: UseOxomiIntegrationOptions) => {
  const documentId = options?.documentId ?? ''
  const [oxomiData, setOxomiData] = useState<{ portal: string; accessToken: string } | null>(null)
  const client = useApolloClient()
  const { t } = useTranslation()

  useEffect(() => {
    const getOxomiAccessToken = async () => {
      try {
        const response = await requestOxomiMetaData(client as ApolloClient<NormalizedCacheObject>)
        if (!response.oxomiToken) return
        if (response.oxomiPortal && response.oxomiToken) {
          setOxomiData({ portal: response.oxomiPortal, accessToken: response.oxomiToken })
        }

        injectOxomiScript()
      } catch (err: unknown) {
        if (err instanceof Error) handleError(err)
      }
    }
    getOxomiAccessToken()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function oxomiLoadedCheck() {
      if (oxomiData && oxomiData.portal && oxomiData.accessToken) {
        const portal = oxomiData.portal
        const accessToken = oxomiData.accessToken
        window.oxomi.init({
          user: 'obeta',
          portal,
          accessToken,
          documentId: documentId,
          infoplayMenuTitle: t('START.OXOMI_CATALOG_SEARCH_ACTION'),
          infoplayItemLookup: searchByOxomiInfoPlayObject,
          errorHandler: function (obj) {
            console.error('Failed to init oxomi:', obj.json.message)
            datadogRum.addError(obj.json.message, {
              type: 'oxomi init',
              portal,
              accessToken,
            })
          },
        })
      }
    }

    document.addEventListener('oxomi-loaded', oxomiLoadedCheck)
    return () => {
      document.removeEventListener('oxomi-loaded', oxomiLoadedCheck)
    }
  }, [documentId, oxomiData, t])

  return oxomiData
}
