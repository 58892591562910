import {
  IListOption,
  IMenuItem,
  IMenuItemsByUserStatus,
  IProfileDropdownList,
  IToolsList,
} from './types'
import { ShopRoutes } from '@obeta/utils/lib/variables'

export const PROJECTS = 'Projekte'
const ORDERS = 'Aufträge'
const ORDERS_NEW = 'Neue Aufträge (Beta)'
const OFFERS = 'Angebote'
const CART_TEMPLATES = 'Merklisten'

export const SEARCH_INPUT_PLACEHOLDER = 'Artikelname/-nummer, EAN, Kategorie'

// Links that used as overlay/popup
export const NativeDrawerMenuOverlays = {
  FEEDBACK_SUPPORT: 'Feedback & Support',
}

const toolboxMenuItem = (): IMenuItem => {
  return {
    title: 'Werkzeugkasten',
    options: [
      { title: 'Konfiguratoren', url: ShopRoutes.ConfiguratorPage },
      { title: 'Mietgeräte', url: ShopRoutes.RentArticlesNew },
      { title: 'Schnellerfassung', url: ShopRoutes.FastProductEntry },
    ],
  }
}

export const menuItems: IMenuItemsByUserStatus = {
  loggedIn: [
    { title: PROJECTS, url: ShopRoutes.Projects },
    { title: ORDERS, url: ShopRoutes.Orders },
    { title: ORDERS_NEW, url: ShopRoutes.OrdersList },
    { title: OFFERS, url: ShopRoutes.OfferList },
    { title: CART_TEMPLATES, url: ShopRoutes.TemplateList },
  ],
  notLoggedIn: [],
}

const actualMenuItemMobile = (): IMenuItem => {
  return {
    title: 'Aktuelles',
    options: [
      { title: 'Nachrichten', url: ShopRoutes.News },
      { title: 'Empfehlungen', url: ShopRoutes.Advertising },
    ],
  }
}

// TODO: We should use a consistent interface to display links. ( IMenuItem vs IToolsList )
export const getActualMenuItemShop = (): IToolsList => ({
  title: 'Aktuelles',
  options: [
    { id: 1, name: 'Nachrichten', url: ShopRoutes.News },
    { id: 2, name: 'Veranstaltungen', url: ShopRoutes.Events },
    { id: 3, name: 'Infotage', url: ShopRoutes.InfoDays },
    { id: 4, name: 'Empfehlungen', url: ShopRoutes.Advertising },
  ],
})

// TODO: We should use a consistent interface to display links. ( IMenuItem vs IToolsList )
export const ordersList: IToolsList = {
  title: ORDERS,
  options: [
    { id: 1, name: ORDERS, url: ShopRoutes.Orders },
    { id: 2, name: ORDERS_NEW, url: ShopRoutes.OrdersList },
  ],
}

// TODO: We should use a consistent interface to display links. ( IMenuItem vs IToolsList )
export const getToolsList = (useNewInterfacesPage: boolean): IToolsList => {
  const options: IListOption[] = [
    { id: 1, name: 'Mietgeräte', url: ShopRoutes.RentArticlesNew },
    { id: 2, name: 'Schnellerfassung', url: ShopRoutes.FastProductEntry },
    {
      id: 3,
      name: 'Schnittstellen',
      url: useNewInterfacesPage ? ShopRoutes.Interfaces : ShopRoutes.Datanorm,
      permission: 'Global_canUseInterfaces',
    },
    { id: 4, name: 'Konfiguratoren', url: ShopRoutes.ConfiguratorPage },
    { id: 5, name: 'Leistungserklärung', url: ShopRoutes.DOP },
  ]

  return {
    title: 'Werkzeugkasten',
    options,
  }
}

const menuItemsMobile: IMenuItemsByUserStatus = {
  loggedIn: [
    { title: ORDERS, url: ShopRoutes.OrdersList },
    { title: OFFERS, url: ShopRoutes.OfferList },
    { title: CART_TEMPLATES, url: ShopRoutes.TemplateList },
  ],
  notLoggedIn: [],
}

const suppliersMenuLinkMobile = { title: 'Lieferanten', url: ShopRoutes.SuppliersOverviewPage }

export const mobileDrawerMenuItems = (isNewSupplierOverview): IMenuItem[] => {
  return [
    ...menuItemsMobile.loggedIn,
    toolboxMenuItem(),
    actualMenuItemMobile(),
    ...(isNewSupplierOverview ? [suppliersMenuLinkMobile] : []),
  ]
}

// MENU APP MOBILE LINKS
export const nativeDrawerMenuItems = (isNewSupplierOverview): IMenuItem[] => {
  return [
    { title: 'Mietgeräte', url: ShopRoutes.RentArticlesNew },
    { title: NativeDrawerMenuOverlays.FEEDBACK_SUPPORT, type: 'overlay' },
    actualMenuItemMobile(),
    ...(isNewSupplierOverview ? [suppliersMenuLinkMobile] : []),
  ]
}

// PROFILE WEB LINKS
export const profileDropdownItems: IProfileDropdownList = {
  loggedIn: [
    { id: 1, name: 'Einstellungen', url: ShopRoutes.Settings },
    {
      id: 2,
      name: 'Benutzerverwaltung',
      url: ShopRoutes.UserAdministration,
      permission: 'Global_canSeeUserManagement',
    },
    {
      id: 5,
      name: 'Meine Buchhaltung',
      url: ShopRoutes.MyAccounting,
      permission: 'Global_canReadAccountInfo',
    },
    {
      url: 'custom',
      id: 'tecselect',
      name: 'Tecselect',
      target: '_blank',
    },
  ],
  notLoggedIn: [],
}

// PROFILE APP LINKS
export const profileDropdownItemsNative: IProfileDropdownList = {
  loggedIn: [
    { id: 1, name: 'Einstellungen', url: ShopRoutes.Settings },
    {
      id: 5,
      name: 'Meine Buchhaltung',
      url: ShopRoutes.MyAccounting,
      permission: 'Global_canReadAccountInfo',
    },
    {
      url: 'custom',
      id: 'tecselect',
      name: 'Tecselect',
      target: '_blank',
    },
  ],
  notLoggedIn: [],
}
