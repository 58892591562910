import { useCallback } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { DatasheetPriceModelInput, ResponseMessage } from '@obeta/schema'
import { trackCustom } from '@obeta/utils/lib/tracking'

type SaveDataSheetPriceInfoResponse = {
  saveDataSheetPriceInfo: ResponseMessage
}

const ResponseTypes = {
  Success: 'Success',
  Error: 'Error',
} as const

const SAVE_DATA_SHEET_PRICE_INFO_MUTATION = gql`
  mutation SaveDataSheetPriceInfo($input: DatasheetPriceModelInput!) {
    saveDataSheetPriceInfo(input: $input) {
      type
      message
    }
  }
`

export const useSaveDataSheetPriceInfo = () => {
  const client = useApolloClient()

  const updateDataSheetPriceInfo = useCallback(
    async (input: DatasheetPriceModelInput): Promise<boolean> => {
      try {
        const response = await client.mutate<SaveDataSheetPriceInfoResponse>({
          mutation: SAVE_DATA_SHEET_PRICE_INFO_MUTATION,
          variables: { input },
        })
        const data = response.data?.saveDataSheetPriceInfo

        if (data?.type === ResponseTypes.Success) {
          return true
        } else {
          const errorMessage = data?.message || 'Unknown error'
          trackCustom(errorMessage)
          return false
        }
      } catch (error) {
        console.error('Error updating datasheet price info:', error)
        trackCustom('updating-datasheet-price-info-error')
        return false
      }
    },
    [client]
  )

  return updateDataSheetPriceInfo
}
