import { Dayjs } from 'dayjs'

export interface OpeningHours {
  weekday: weekdays
  from: string
  to: string
}

export interface OpeningTime {
  weekday: weekdays
  from: Dayjs | null
  to: Dayjs | null
}

export interface OpeningHoursFormatted {
  days: string
  time: string
}

export interface OpeningHoursTomorrow {
  selfCollectionNextDayOpen: boolean
  selfCollectionOpeningHoursDays: string[]
  selfCollectionOpeningHoursTimes: string[]
  selfCollectionTomorrowOpenFrom: string
  selfCollectionTomorrowOpenUntil: string
}

export enum weekdays {
  monday = 1,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday = 0 | 7, //(Sunday might be 0 OR 7)
}
