import { isSubuser } from './isSubuser'

interface IUserIdentifier {
  userId?: string
  companyId?: string
}

export const isAdmin = ({ userId, companyId }: IUserIdentifier): boolean => {
  if (!userId || !companyId) return false

  const isAdminUser = !isSubuser({ userId, companyId })
  return isAdminUser
}
