import { useState, createContext, useContext, RefObject } from 'react'
import { useObetaResizeObserver } from '../hooks/useObetaResizeObserver'

interface IHeaderDimension {
  height: number
}

export interface HeaderDimensionsProps {
  dimensions: IHeaderDimension
  updateDimensions?: (value: IHeaderDimension) => void
}

export const HeaderDimensionsContext = createContext<HeaderDimensionsProps>({
  dimensions: {
    height: 0,
  },
  updateDimensions: () => {
    // empty function
  },
})

export const HeaderDimensionsContextProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState({
    height: 0,
  })

  const updateDimensions = (value) => setDimensions(value)

  return (
    <HeaderDimensionsContext.Provider
      value={{
        dimensions,
        updateDimensions,
      }}
    >
      {children}
    </HeaderDimensionsContext.Provider>
  )
}

export const useHeaderDimensions = (
  headerRef?: RefObject<HTMLElement> | null
): HeaderDimensionsProps => {
  const { dimensions, updateDimensions } = useContext(HeaderDimensionsContext)

  useObetaResizeObserver(headerRef || null, (entry) => {
    const height = entry.contentRect.height
    height && updateDimensions?.({ height })
  })

  return { dimensions }
}
