import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useShoppingCartById } from '@obeta/data/lib/hooks/useShoppingCartById'
import { isPickup } from '@obeta/utils/lib/cart/isPickup'
import { ShippingGroup } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { useSessionCart } from '@obeta/data/lib/hooks/useSessionCart'

export const useAddressInfo = () => {
  const { user } = useUserDataV2()
  const sessionCart = useSessionCart()
  const cart = useShoppingCartById(user?.settings?.defaultCartId ?? '') ?? sessionCart

  let shippingGroup
  if (cart) {
    shippingGroup = isPickup(cart.shippingData) ? ShippingGroup.Pickup : ShippingGroup.Delivery
  }

  return { showAddressInfo: cart, shippingGroup, cart }
}
