import { useState, useEffect, useRef } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { DatasheetPriceModel } from 'graphql-codegen'

export const DataSheetPriceInfoQuery = gql`
  query GetDataSheetPriceInfo {
    getDataSheetPriceInfo {
      includeVAT
      offerPrice {
        priceMode
        percentBasedPrice {
          type
          percent
        }
      }
      pricePart {
        showListPrice
        showCatalogPrice
        showMetalAddition
      }
    }
  }
`

export const useGetDataSheetPriceInfo = () => {
  const [priceInfo, setPriceInfo] = useState<DatasheetPriceModel | null>(null)
  const client = useApolloClient()
  const isMounted = useRef(true)

  useEffect(() => {
    const getDataSheetPriceInfo = async () => {
      try {
        const response = await client.query({
          query: DataSheetPriceInfoQuery,
        })

        const result = response.data?.getDataSheetPriceInfo
        const { includeVAT, offerPrice, pricePart } = result
        const cleanedData = {
          includeVAT,
          offerPrice: {
            priceMode: offerPrice.priceMode,
            percentBasedPrice: {
              type: offerPrice.percentBasedPrice.type,
              percent: offerPrice.percentBasedPrice.percent,
            },
          },
          pricePart: {
            showListPrice: pricePart.showListPrice,
            showCatalogPrice: pricePart.showCatalogPrice,
            showMetalAddition: pricePart.showMetalAddition,
          },
        }

        if (isMounted.current) {
          setPriceInfo(cleanedData)
        }
      } catch (error) {
        handleError(
          new Error(`Apollo client throws error in getDataSheetPriceInfo: ${error.message}`)
        )
      }
    }
    getDataSheetPriceInfo()

    return () => {
      isMounted.current = false
    }
  }, [client])

  return priceInfo
}
