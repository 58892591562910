import { useState, useEffect, useRef } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { CustomerCompanyDataModel } from 'graphql-codegen'

export const dataSheetCustomerCompanyDataQuery = gql`
  query GetDataSheetCustomerCompanyData {
    getDataSheetCustomerCompanyData {
      companyName
      contactPerson
      streetAndNumber
      zipCode
      city
      website
      mail
      phone
      fax
      openingHours {
        weekday
        from
        to
      }
      companyLogo
    }
  }
`

export const useGetDataSheetCustomerCompanyData = () => {
  const [companyData, setCompanyData] = useState<CustomerCompanyDataModel | null>(null)
  const client = useApolloClient()
  const isMounted = useRef(true)

  useEffect(() => {
    const getDataSheetCustomerCompanyData = async () => {
      try {
        const response = await client.query({
          query: dataSheetCustomerCompanyDataQuery,
        })

        const result = response.data?.getDataSheetCustomerCompanyData
        const { loading, networkStatus, __typename, ...cleanedData } = result
        if (isMounted.current) {
          setCompanyData(cleanedData)
        }
      } catch (error) {
        handleError(
          new Error(
            `Apollo client throws error in getDataSheetCustomerCompanyData: ${error.message}`
          )
        )
      }
    }
    getDataSheetCustomerCompanyData()

    return () => {
      isMounted.current = false
    }
  }, [client])

  return companyData
}
