import React, { useEffect, useState } from 'react'
import generateShortUUID from 'short-uuid'
import { waitForField, waitForResolve } from '@obeta/utils/lib/waitFor'
import { OxomiGlobal } from '@obeta/models/lib/models/Oxomi'
import styles from './OxomiHiddenScrollAnchor.module.scss'

export const OxomiHiddenScrollAnchor = ({ oxomiCatalogId }: { oxomiCatalogId: string }) => {
  const [oxomiTargetId] = useState(generateShortUUID.generate)

  // When Oxomi opens the preview for the first time, it does not retain the previous scroll position.
  // Since we have no control over this process, we mount Oxomi in a container where we want to preserve the scroll position,
  // without displaying the content, by keeping the container set to display: none.
  useEffect(() => {
    const embedImageFromDocId = async () => {
      if (oxomiCatalogId) {
        const oxomi = await waitForField<OxomiGlobal>({ field: 'oxomi' })
        await waitForResolve({ callback: oxomi.isInitialized })

        window.oxomi.embedDocument({
          target: `#${oxomiTargetId}`,
          catalog: oxomiCatalogId,
          showDetails: false,
        })
      }
    }

    embedImageFromDocId()
  }, [oxomiCatalogId, oxomiTargetId])

  return <div id={oxomiTargetId} className={styles.oxomixEntryPoint} />
}
