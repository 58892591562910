import React, { FC } from 'react'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { Typography } from '@mui/material'
import { Section } from '../../Section'
import { TeasersSection } from '../../TeasersSection'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { ITeaserSectionProps } from '../../types'
import { ReactComponent as ImportContacts } from 'assets/icon/designsystem/import_contacts.svg'
import { DarkButton } from '@obeta/components/lib/custom-button/CustomButton'
import { useOxomiIntegration } from '@obeta/data/lib/hooks/useOxomiIntegration'
import { OxomiHiddenScrollAnchor } from '../oxomiHiddenScrollAnchor/OxomiHiddenScrollAnchor'
import { ILastMonthAds } from '@obeta/models/lib/models/LastMonthAds/LastMonthAds'
import styles from './TeaserSectionPanel.module.scss'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'

type TeaserSectionPanelProps = ITeaserSectionProps & {
  currentMonthAdverts: ILastMonthAds[]
}

export const TeaserSectionPanel: FC<TeaserSectionPanelProps> = (props) => {
  const { t } = useTranslation()
  const { mobile } = useBreakpoints()
  const history = useHistory()
  const { isLoggedIn } = useUserDataV2()

  const currentMonthOxomiCatalogId = String(props.currentMonthAdverts[0]?.oxomiCatalogId ?? '')

  // TODO: check if documentId is needed, since its not documented in v2 docs
  // removed for now, rollback if needed
  useOxomiIntegration()

  const handleRightAdornmentClick = () => history.push(ShopRoutes.Advertising)

  const openCurrentMonthOxomiCatalog = () =>
    window.oxomi.openDocument({ catalog: currentMonthOxomiCatalogId })

  const rightAdornmentTeasers: JSX.Element = (
    <div className={styles.teasersControlWrapper}>
      {currentMonthOxomiCatalogId && isLoggedIn && (
        <DarkButton
          leftIcon={mobile ? <ImportContacts /> : null}
          onClick={openCurrentMonthOxomiCatalog}
        >
          {!mobile && (
            <Typography className={styles.teasersPreviewText} variant="bodyBold">
              {t('TEASERS.VIEW_BROCHURE_DESKTOP')}
            </Typography>
          )}
        </DarkButton>
      )}
      <Typography
        variant="bodyBold"
        onClick={handleRightAdornmentClick}
        className={styles.showAllTeasers}
      >
        {t(!mobile ? 'START.ALL_SUGGESTIONS_ADORNMENT' : 'START.ALL_SUGGESTIONS_MOBILE_ADORNMENT')}
      </Typography>
    </div>
  )

  return (
    <>
      <Section
        title={t('START.TEASERS')}
        rightAdornment={rightAdornmentTeasers}
        content={<TeasersSection maxEls={props.maxEls} placement={props.placement} />}
      />
      {currentMonthOxomiCatalogId && (
        <OxomiHiddenScrollAnchor oxomiCatalogId={currentMonthOxomiCatalogId} />
      )}
    </>
  )
}
