import React, { FunctionComponent, useRef, useState } from 'react'
import { Box, Button, Card, Stack, SvgIcon } from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import styles from './Notification.module.scss'
import clsx from 'clsx'

import { StackProps } from '@mui/system'
import { useObetaResizeObserver } from '@obeta/data/lib/hooks/useObetaResizeObserver'

interface Props {
  id?: string
  color?: string
  Icon: FunctionComponent
  actionEl?: React.ReactNode
  closeHandler?: (id: string) => void
  children: React.ReactNode
  borderless?: boolean //no marker on the left side
  flat?: boolean //no elevation
}

/**
 * this component allows proper wrapping on resizing in notifications
 * @returns
 */
export const BaseNotificationRow: React.FC<StackProps & { title?: string }> = ({
  children,
  direction,
  spacing,
  ...rest
}) => {
  return (
    <Stack className={styles.row} direction={'row'} alignItems={'flex-start'} {...rest}>
      {children}
    </Stack>
  )
}

export const BaseNotificationLayout: React.FC<Props> = (props) => {
  const {
    id,
    color = 'darkGray55K',
    Icon,
    actionEl,
    closeHandler,
    children,
    borderless = false,
    flat = false,
  } = props
  const handleClose = () => {
    if (closeHandler && id) {
      closeHandler(id)
    }
  }
  const notifcationWrapperRef = useRef<HTMLDivElement>(null)
  const actionElWrapRef = useRef<HTMLDivElement>(null)

  const [stackActionEls, setStackActionEls] = useState(false)
  const [decoratorMargin, setDecoratorMargin] = useState<string>()

  useObetaResizeObserver(notifcationWrapperRef, (entry) => {
    if (entry.target?.getBoundingClientRect()?.width <= 600) {
      setStackActionEls(true)
    } else {
      setStackActionEls(false)
    }
    /**
     * the "action el" can hold higher elements then the normal row
     * therefore we measure it and apply any diff to align the close button and the
     * type icon properly
     * **/

    if (!stackActionEls && actionElWrapRef.current) {
      setDecoratorMargin(`${(actionElWrapRef.current.getBoundingClientRect().height - 20) / 2}px`)
    } else {
      setDecoratorMargin(undefined)
    }
  })

  return (
    <Card ref={notifcationWrapperRef} elevation={flat ? undefined : 2} className={styles.card}>
      <Stack
        className={clsx(styles.cardContent, styles[color], {
          [styles['leftborder']]: color,
          [styles['borderless']]: borderless,
          [styles.withoutActionEl]: !actionEl || stackActionEls,
        })}
        padding={'1rem'}
        gap=".5rem"
      >
        <Box marginTop={decoratorMargin} className={styles.icon}>
          <Icon />
        </Box>
        <Stack
          marginTop={decoratorMargin}
          marginBottom={decoratorMargin}
          direction={'column'}
          flex={1}
          gap={'0.5rem'}
          overflow={'hidden'}
        >
          {children}
          {/** these are supposed to be notification rows for proper sizing but you can use what ever you like */}
          {stackActionEls && actionEl ? <Box ref={actionElWrapRef}>{actionEl}</Box> : null}
        </Stack>
        {!stackActionEls && actionEl ? (
          <Box>
            <Box ref={actionElWrapRef}>{actionEl}</Box>
          </Box>
        ) : null}

        {closeHandler && (
          <Box marginTop={decoratorMargin}>
            <Button
              variant={'tertiary'}
              startIcon={<SvgIcon component={CloseIcon} />}
              onClick={handleClose}
              className={styles.closeButton}
            />
          </Box>
        )}
      </Stack>
    </Card>
  )
}
