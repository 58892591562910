import { FC, useContext, createContext, useState, useEffect, useRef } from 'react'
import { RxDatabase, RxLocalDocument } from 'rxdb'
import { RxLocalDocumentData } from 'rxdb/dist/types/types'
import { useRxDB } from 'rxdb-hooks'
import { ShoppingCartV2 } from '@obeta/models/lib/models'
import { CustomerMetaData } from './useUserDataV2'
import { useAppActions } from './useAppActions'
import { useHistory } from './useHistoryApi'
import { useEntities } from './useEntities'
import { manuallyUpdateCarts } from '../actions'
import { useDispatch } from 'react-redux'

interface SessionMeta {
  isReadyToInitializeSession: boolean
}

interface SessionMetaContext {
  sessionMeta: SessionMeta
  setSessionMeta: (sessionMeta: SessionMeta) => void
}

const initialSessionMeta = {
  isReadyToInitializeSession: false,
}

export const SessionMetaContext = createContext<SessionMetaContext>({
  sessionMeta: initialSessionMeta,
  setSessionMeta: () => 0,
})

export const SessionMetaProvider: FC = ({ children }) => {
  const [sessionMeta, setSessionMeta] = useState<SessionMeta>(initialSessionMeta)
  const [userMetaSub, setUserMetaSub] = useState<RxLocalDocument<
    RxDatabase,
    CustomerMetaData
  > | null>(null)
  const [userMetaData, setUserMetaData] = useState<CustomerMetaData | null>(null)
  const [isLoginStateReady, setIsLoginStateReady] = useState<boolean>(false)
  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const db = useRxDB()
  const history = useHistory()
  const appActions = useAppActions()
  const pathname = history.location.pathname
  const isSessionCatalogEntry = history.location.pathname.startsWith('/session-catalog-start')
  const isLogoutInProgress = useRef(false)
  const dispatch = useDispatch()
  const didManualCartUdpate = useRef(false)
  // set user data
  useEffect(() => {
    const initUserMetaSub = async () => {
      const userMeta = await db.getLocal<CustomerMetaData>('usermeta')
      setUserMetaSub(userMeta)
    }
    initUserMetaSub()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    appActions.tokens$.subscribe((tkns) => {
      if (tkns?.accessToken && !didManualCartUdpate.current && isLoginStateReady) {
        dispatch(manuallyUpdateCarts())
        didManualCartUdpate.current = true
      }
    })
  }, [appActions.tokens$, dispatch, isLoginStateReady, userMetaData])

  // listen for user data changes
  useEffect(() => {
    if (userMetaSub) {
      const subCustomerMetaData = userMetaSub.$.subscribe(
        (doc: RxLocalDocumentData<CustomerMetaData>) => {
          setUserMetaData(doc.data)
        }
      )
      return () => {
        subCustomerMetaData.unsubscribe()
      }
    }
  }, [dispatch, userMetaSub])

  // Log out if user is logged in, otherwise set isLoginStateReady without further actions.
  useEffect(() => {
    let sub
    if (isSessionCatalogEntry) {
      if (userMetaData && !isLoginStateReady) {
        if (
          (userMetaData.isLoggedIn && !isLogoutInProgress.current) ||
          (!userMetaData.isLoggedIn && isLogoutInProgress.current)
        ) {
          if (!isLogoutInProgress.current) {
            appActions.logout$.next(true)
            isLogoutInProgress.current = true
          }
          sub = appActions.logoutFinished$.subscribe(() => {
            setIsLoginStateReady(true)
          })
        } else {
          setIsLoginStateReady(true)
        }
      }
    }
    return () => {
      sub?.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMetaData])

  // set isReadyToInitializeSession if all conditions are met
  useEffect(() => {
    const didSessionLogin = userMetaData?.userId && userMetaData?.companyId
    if (isLoginStateReady && didSessionLogin && carts.length > 0) {
      setSessionMeta({ isReadyToInitializeSession: true })
    }
  }, [userMetaData, pathname, isLoginStateReady, carts])

  return (
    <SessionMetaContext.Provider value={{ sessionMeta, setSessionMeta }}>
      {((isSessionCatalogEntry && isLoginStateReady) || !isSessionCatalogEntry) && children}
    </SessionMetaContext.Provider>
  )
}

export const useSessionMeta = (): SessionMetaContext => {
  return useContext(SessionMetaContext)
}
