import { StockAvailabilityEstimate } from '@obeta/schema'

export const getStockAvailabilityEstimateString = (
  stockAvailabilityEstimate: StockAvailabilityEstimate,
  isDisplayedWithinTooltip: boolean
): string => {
  const unitMap: { [key: string]: (minValue: number, maxValue: number) => string } = {
    days: (minValue, maxValue) =>
      (isDisplayedWithinTooltip ? 'Arbeitstag' : 'Tag') +
      (minValue !== maxValue || maxValue > 1 ? (isDisplayedWithinTooltip ? 'en' : 'e') : ''),
    weeks: (minValue, maxValue) => 'Woche' + (minValue !== maxValue || maxValue > 1 ? 'n' : ''),
    unknown: () => '',
  }

  if (stockAvailabilityEstimate && stockAvailabilityEstimate.error === '') {
    const unit = stockAvailabilityEstimate.unit
    const minValue = stockAvailabilityEstimate.minValue
    const maxValue = stockAvailabilityEstimate.maxValue
    const parsedUnit = unitMap[unit](minValue, maxValue)

    const baseString = `ca. ${minValue}`
    const rangeString = minValue === maxValue ? '' : ` - ${maxValue}`
    const workdayPostFix = isDisplayedWithinTooltip && unit === 'days' ? ' (Mo - Fr)' : ''

    return `${baseString}${rangeString} ${parsedUnit}${workdayPostFix}`
  }
  return ''
}
