import { withProduct } from './withProduct'
import { Base } from './Base'
import { makeShopProductCard } from './makeShopProductCard'
import { makeCartItem } from './makeCartItem'
import { makeSearchResultItem } from './makeSearchResultItem'
import { withLinkToArticleDetail } from './withLink'
import { withBaseCounter } from './withBaseCounter'
import { makeTemplateItem } from './makeTemplateItem'
import { makeOfferItem } from './makeOfferItem'
import { makeOrderItem } from './makeOrderItem'
import { makeCancelledOfferItem } from './makeCancelledOfferItem'
import { makeElbridgeOrFastProductEntryItem } from './makeElbridgeOrFastProductEntryItem'
import { makeFastProductEntryItem } from './makeFastProductEntryItem'
import { makeUnknownOrderItem } from './makeUnknownOrderItem'
import { memo } from 'react'
export * from './baseTypes'
export * from './makeShopProductCard'
export * from './ArticleAccesoriesCard'
export * from './ArticleCarouselCard'

// TODO: enhancer for layouts: DO NOT DUPLICATE reusable pieces of code.
// TODO: title of card cannot be bigger that product-image.
// TODO: "withProduct" is coupled "withBaseCounter": I need a better way
// that won't coupled this enhancers together (problem is "minimumAmount"): maybe adition "render" prop that will pass product
const ProductCard = withProduct(withBaseCounter(Base))

/* Card used, amongst others, for products displayed as "Exklusiv im Webshop", "ähnliche Artikel", "Kunden kauften auch", as well as „passendes Zubehör“ */
export const ShopProductCard = withLinkToArticleDetail(
  makeShopProductCard(ProductCard),
  (props) => {
    return props.product.sapId
  }
)

export const CartItem = withLinkToArticleDetail(makeCartItem(Base), (props) => {
  return props.shoppingCartItem.sapId
})
export const TemplateItem = memo(
  withLinkToArticleDetail(makeTemplateItem(Base), (props) => {
    return props.cartTemplateItem.productId
  })
)
export const OfferItem = memo(
  withLinkToArticleDetail(makeOfferItem(Base), (props) => {
    return props.offerItem.sapId
  })
)

export const OrderItem = memo(
  withLinkToArticleDetail(makeOrderItem(Base), (props) => {
    return props.orderItem.product?.sapId ?? ''
  })
)

export const OrderItemWithoutLinkToArticleDetail = memo(makeOrderItem(Base))
export const CancelledOfferItem = makeCancelledOfferItem(Base)
export const SearchResultItemBase = memo(makeSearchResultItem(ProductCard))
export const SearchResultItem = withLinkToArticleDetail(SearchResultItemBase, (props) => {
  return props.product.sapId
})
export const UnknownOrderItem = makeUnknownOrderItem(Base)
export * from './ArticleAccesoriesCard' // instance of ShopProductCard (wrapped by withLinkToArticleDetail)
export * from './ArticleCarouselCard' // instance of ShopProductCard (wrapped by withLinkToArticleDetail)

export const ElbridgeOrFastProductEntryItem = withLinkToArticleDetail(
  makeElbridgeOrFastProductEntryItem(Base),
  (props) => {
    return props.productItem.sapId
  }
)

export const FastProductEntryItem = withLinkToArticleDetail(
  makeFastProductEntryItem(Base),
  (props) => {
    return props.product.sapId
  }
)
