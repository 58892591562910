// List of pages with full width (0 x-axis page margin required)
export const PAGES_FULL_WIDTH: RegExp[] = [RegExp(/article-detail(.*)/), RegExp(/^\/stores$/)]

// List of pages without displaying the header
export const PAGES_HEADERLESS: RegExp[] = [RegExp(/^\/pdf-overview(.*)/)]

// List of all pages that shall be rendered on a white background
export const WHITE_BG_PAGES_REGEXP_WEB: RegExp[] = [
  // Current routes with special style
  RegExp(/^\/advertising(.*)/),
  RegExp(/^\/article-detail(.*)/),
  RegExp(/^\/content(.*)/),
  RegExp(/^\/news-detail(.*)/),
  RegExp(/^\/tecworld(.*)/),
  RegExp(/^\/story(.*)/),
  // Legacy iframe base shop routes
  RegExp(/^\/account$/),
  RegExp(/^\/activate$/),
  RegExp(/^\/baupvo$/),
  RegExp(/^\/company$/),
  RegExp(/^\/configurators$/),
  RegExp(/^\/datanorm$/),
  RegExp(/^\/dop$/),
  RegExp(/^\/events$/),
  RegExp(/^\/info-days$/),
  RegExp(/^\/lieferanten$/),
  RegExp(/^\/offers$/),
  RegExp(/onlineshop(.*)/),
  RegExp(/^\/open-invoice-items$/),
  RegExp(/^\/orders$/),
  RegExp(/^\/projects$/),
  RegExp(/^\/quick-register$/),
  RegExp(/^\/privacy-protection$/),
  RegExp(/^\/promotion(.*)/),
  RegExp(/^\/register$/),
  RegExp(/^\/services$/),
  RegExp(/teaser-detail(.*)/),
  RegExp(/^\/upload$/),
  RegExp(/^\/user$/),
]

/**
 * Match page urls to apply different styles.
 * @param asPath asPath
 * @param pages Page list
 * @returns Boolean
 */
export const isPageMatch = (asPath: string, pages: RegExp[]): boolean => {
  for (const page of pages) {
    if (asPath.match(page)) return true
  }
  return false
}

/**
 * Checks if current route is the start page.
 * @param asPath asPath
 * @returns Boolean
 */
export const isStartPage = (asPath: string): boolean => asPath === '/'

export const WHITE_BG_PAGES_REGEXP_APP = [
  RegExp(/^\/start$/),
  RegExp(/^\/articles$/),
  RegExp(/^\/promotion$/),
  RegExp(/^\/rent-articles$/),
  RegExp(/^\/promotion(.*)/),
  RegExp(/teaser-detail(.*)/),
  RegExp(/^\/tecworld(.*)/),
  RegExp(/^\/advertising(.*)/),
  RegExp(/^\/article-detail(.*)/),
  RegExp(/^\/news-detail(.*)/),
  RegExp(/^\/content(.*)/),
  RegExp(/^\/tecworld(.*)/),
]
