import { RefObject, ForwardedRef } from 'react'
import useResizeObserver, { UseResizeObserverCallback } from '@react-hook/resize-observer'
import { ResizeObserver as JuggleResizeObserver } from '@juggle/resize-observer'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'

export const useObetaResizeObserver = <T extends Element>(
  target: RefObject<T> | ForwardedRef<T> | T | null,
  callback: UseResizeObserverCallback
) => {
  // In newer versions of the observer, the 'window' object was introduced, but it's not supported when parsing code on the server (e.g., print service).
  // To handle this, we use a polyfill.
  const isPrintService = isUiTarget('printService')

  const polyfill = isPrintService ? JuggleResizeObserver : null

  useResizeObserver(target, callback, { polyfill })
}
