import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { ReactComponent as CheckIcon } from 'assets/icon/designsystem/check.svg'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { SecondaryButton, TertiaryButton } from '../custom-button/CustomButton'
import styles from './Confirm.module.scss'
import { useTranslation } from 'react-i18next'

interface Props {
  heading: string
  body: string
  handleConfirm: () => void
  handleCancel: () => void
  openConfirmDialog: boolean
  changesInCart?: boolean
}

export const Confirm: React.FC<Props> = (props) => {
  const { body, heading, handleConfirm, handleCancel, openConfirmDialog, changesInCart } = props
  const { t } = useTranslation()

  return (
    <Dialog
      className={styles.dialog}
      open={openConfirmDialog}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={styles.dialogTitle}>
        {heading}
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Typography variant={'body'} color={'secondary'}>
          {body}
        </Typography>
      </DialogContent>
      <LightGrayDivider className={styles.divider} />
      <DialogActions className={styles.dialogActions}>
        <TertiaryButton
          fullWidth={false}
          iconColor={'primary'}
          leftIcon={<CloseIcon />}
          onClick={handleCancel}
        >
          {t('SHOPPING_CART.ACTIONS.ABORT')}
        </TertiaryButton>
        <SecondaryButton
          fullWidth={false}
          iconColor={'success'}
          leftIcon={<CheckIcon />}
          onClick={handleConfirm}
          autoFocus
        >
          {changesInCart ? t('SHOPPING_CART.ACTIONS.SAVE') : t('SHOPPING_CART.ACTIONS.CONFIRM')}
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  )
}
