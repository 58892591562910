import {
  SwiperSlider,
  TAdornment,
  INavState,
} from '@obeta/components/lib/swiper-slider/SwiperSlider'
import React, { useCallback, useEffect, useRef, FC, useMemo, useState } from 'react'
import { SwiperSlide } from 'swiper/react'
import { Swiper } from 'swiper'
import { NavigateButton } from '@obeta/components/lib/navigate-button/NavigateButton'
import clsx from 'clsx'
import { useProductCollectionActions } from '@obeta/data/lib/hooks/useProductCollectionActions'
import styles from './ProductsSwiper.module.scss'

const createSlides = (elements: (JSX.Element | null)[], cardWrapperClassName?: string) => {
  return elements.filter(Boolean).map((el, i) => {
    return (
      <SwiperSlide className={clsx(styles.swiperSlide, cardWrapperClassName)} key={i}>
        {el}
      </SwiperSlide>
    )
  })
}

interface ISwiperNavigationProps {
  isHidden: boolean
  isCloseNavButton: boolean
  swiperApi: Swiper | null
  direction: 'left' | 'right'
  navState: INavState
  isSliderLoop?: boolean
}

const SwiperNavigation: FC<ISwiperNavigationProps> = ({
  isHidden,
  isCloseNavButton,
  swiperApi,
  direction,
  isSliderLoop,
  navState,
}) => {
  const { onSwipeLeft, onSwipeRight } = useProductCollectionActions()
  const handleNavigate = useCallback(() => {
    if (direction === 'right') {
      swiperApi?.slideNext()
      onSwipeRight()
    } else {
      swiperApi?.slidePrev()
      onSwipeLeft()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiperApi, direction])

  const isDisabled = useMemo(() => {
    if (isSliderLoop) {
      return false
    }

    return (
      (direction === 'right' && navState.isEnd) || (direction === 'left' && navState.isBeginning)
    )
  }, [direction, isSliderLoop, navState])

  return (
    <NavigateButton
      className={clsx({
        [styles.navigateLeft]: direction === 'left',
        [styles.navigateRight]: direction === 'right',
        [styles.hiddenNav]: isHidden,
        [styles.closeNavButton]: isCloseNavButton,
      })}
      direction={direction}
      onClick={handleNavigate}
      disabled={isDisabled}
    />
  )
}

const getSlidesPerGroup = (elementsPerRow: number | 'auto', swiperInstance: Swiper) => {
  if (elementsPerRow !== 'auto') return elementsPerRow

  const { slidesSizesGrid, width: swiperContainerWidth } = swiperInstance

  const slideWidth = slidesSizesGrid[0]

  return Math.floor(swiperContainerWidth / slideWidth)
}

/**
 *
 * @param productElements - make sure that array is empty if data to create elements is not available
 *  otherwise swiperjs won't calculate dimensions as expected on first render
 * @returns
 */
export const ProductsSwiper: React.FC<{
  productElements: JSX.Element[]
  elementsPerRow?: number | 'auto'
  withNavigateButtons?: boolean
  closeNavigationButtons?: boolean
  cardWrapperClassName?: string
  isSliderLoop?: boolean
  allowTouchMove?: boolean
  initialSlide?: number
  slidesPerGroupAuto?: boolean
}> = (props) => {
  const {
    withNavigateButtons,
    productElements,
    elementsPerRow = 'auto',
    allowTouchMove = true,
    cardWrapperClassName,
    closeNavigationButtons = false,
    initialSlide = 0,
    slidesPerGroupAuto = true,
  } = props
  const swiperApi = useRef<Swiper | null>(null)
  const [slidesPerGroupAutoCount, updateSlidesPerGroupAutoCount] = useState<number | undefined>(
    undefined
  )

  useEffect(() => {
    swiperApi.current?.update()
  }, [elementsPerRow])

  // In case slidesPerGroup is set to 'auto', we need to be able to detect this and modify it after initialization.
  // Thanks to using the ref callback, we don't need to listen to the resize event after but still need to  "reinitialize" the swiper set this changes
  const swiperCallbackRef = useCallback(
    (swiperInstance: Swiper) => {
      if (!swiperInstance) return

      swiperApi.current = swiperInstance
      if (elementsPerRow === 'auto' && slidesPerGroupAutoCount === undefined) {
        updateSlidesPerGroupAutoCount(getSlidesPerGroup(elementsPerRow, swiperInstance))
      }
    },
    [elementsPerRow, slidesPerGroupAutoCount]
  )

  if (productElements.length <= 0) {
    return null
  }

  const isSliderLoop =
    props.isSliderLoop ??
    (elementsPerRow === 'auto' ? false : productElements.length > elementsPerRow)
  let leftAdornment: TAdornment | undefined
  let rightAdornment: TAdornment | undefined
  /**
   * dont render no nav as the context determines if one is possible and then needs to adjust its spacing accordingly
   * we only assume that arrows dont make sense if there is nothing to scroll
   * */
  const hiddenNav = elementsPerRow !== 'auto' && productElements.length + 1 <= elementsPerRow
  if (withNavigateButtons && !hiddenNav) {
    leftAdornment = (navState) => (
      <SwiperNavigation
        isHidden={hiddenNav}
        isCloseNavButton={closeNavigationButtons}
        swiperApi={swiperApi.current}
        direction="left"
        navState={navState}
        isSliderLoop={isSliderLoop}
      />
    )
    rightAdornment = (navState) => (
      <SwiperNavigation
        isHidden={hiddenNav}
        isCloseNavButton={closeNavigationButtons}
        swiperApi={swiperApi.current}
        direction="right"
        navState={navState}
        isSliderLoop={isSliderLoop}
      />
    )
  }

  return (
    <SwiperSlider
      key={`productSwiper ${slidesPerGroupAutoCount}`}
      options={{
        loop: isSliderLoop,
        slidesPerView: elementsPerRow,
        slidesPerGroup: elementsPerRow === 'auto' ? slidesPerGroupAutoCount : elementsPerRow,
        allowTouchMove: allowTouchMove,
        resizeObserver: true,
        slidesPerGroupAuto,
        spaceBetween: 24,
        initialSlide,
      }}
      leftAdornment={leftAdornment}
      rightAdornment={rightAdornment}
      ref={swiperCallbackRef}
      pagination={true}
      scrollbar={false}
      className={clsx(styles.root, {
        [styles.withNavigateButtons]: withNavigateButtons && !hiddenNav,
      })}
    >
      {createSlides(productElements, cardWrapperClassName)}
    </SwiperSlider>
  )
}
