/**
 * Shopping Cart Data.
 */
import { PaymentMethod } from '@obeta/schema'
import { ArticleListItem } from '../Article/ArticleListItem'
import { DeliveryAddress } from './DeliveryAddress'
import { DeliveryDate } from './DeliveryDate'
import { ProductAggregate } from '../Article/Shop/Product'
import { ShippingData } from '../ShoppingCart/ShippingData'
import { ShippingType } from './ShippingOptions'

export interface ShoppingCartPrices {
  net: number
  metal: number
  shipping: number
  vat: number
  netSum: number
  totalSum: number
  currency: string
  tecselect: number
}

export interface ShoppingCartItemPrices {
  lineItemId: string
  metalNeAddition: number
  netPrice: number
  tecSelect: number
  strikeThroughPrice: number
  listPrice?: number
  currency: string
}

export interface ShoppingCartItem {
  id: string
  cartId?: string
  position?: number

  offerItemPosition?: number
  product: ProductAggregate
  sapId: string
  amount: number
  prices?: ShoppingCartItemPrices
  isPromotionItem?: boolean
}

export interface CartMoveItemsToAdd {
  sapId: string
  amount: number
  offerId: string
  offerItemPosition: string
}

export interface promotionSettings {
  id: string
  name: string
  description: string
  promotionGift: string
  promotionNoticeEmailRecipients: string[]
}

export interface ShoppingCartV2 {
  id: string
  cartType: string
  projectId: string
  name: string
  receiverId: string
  commission: string
  clerk: string
  offerId: string
  remark: string
  phone: string
  email: string
  collector: string
  items: ShoppingCartItem[]
  shippingData: ShippingData
  promotionSettings?: promotionSettings
  shoppingCartPrices?: ShoppingCartPrices
  messages: [
    {
      type: ShoppingCartNotificationTypes
      text: string
    }
  ]
  promotionId?: string
  updatedAt: string
  paymentMethod: PaymentMethod
  isUpdating?: boolean
}

export interface partialCartInfo {
  remark?: string
  phone?: string
  commission?: string
  shippingType?: ShippingType
  shippingData?: {
    //Actually there should always be shippingType and shippingDate. Setting these as optional is only to compensate for the issue that sometimes either
    // of these properties are missing in the data coming from GraphQL
    //TODO: hence, can be mandatory properties once the above issue has been addressed
    shippingType?: ShippingType
    shippingDate?: string
  }
}

export interface ShoppingCartUpdateOutput {
  updateResults: ShoppingCartUpdateResult
}

export class ShoppingCartUpdateResult {
  success: boolean
  errorCode: string
  errorMessage: string
  itemResults: ShoppingCartItemResult[]
}

export class ShoppingCartItemResult {
  success: boolean
  errorCode: string
  errorMessage: string
  amountChanged: boolean
}

export enum ShoppingCartNotificationTypes {
  default = 'default',
}

export interface ShoppingCart {
  id: string
  name: string
  orderNumber: number
  active: {
    deliveryType: string
    deliveryDate: string
    deliveryAddress: DeliveryAddress
    deliveryAddressNr: string
    additionalText: string
    commission: string
    offerNo: string
    phoneNumber: string
    isCompleteDelivery: boolean
  }
  flags: {
    isActiveDeliveryTypeNightshift: boolean
    isCompleteDeliveryPossible: boolean
    isPartialDeliveryPossible: boolean
    isPromotionActive: boolean
  }
  prices: {
    netPrice: string
    shippingPrice: string
    taxPrice: string
    sumPrice: string
    metalAddPrice: string
    orderSumPrice: string
  }
  deliveryDates: DeliveryDate[]
  articleList: ArticleListItem[]
  backLogs: number
  messages: {
    articleList: [
      {
        ring: string
        text: string
      }
    ]
    deliveryOptions: [
      {
        ring: string
        text: string
        color: string
      }
    ]
    shoppingCart: [
      {
        ring: string
        text: string
        color: string
        order: string
      }
    ]
  }
  tecselectValue: string | null
}

export interface SimpleShoppingCart {
  id: string
  name: string
}

export interface ShoppingCartAddItemsInput {
  input: {
    cartId: string
    items: Array<{ sapId: string; amount: number; offerId: string; offerItemPosition: string }>
  }
}

export interface ShoppingCartForDropdown {
  id: string
  name: string
  articleCount: number
  offerId?: string
  offerName?: string
  count: number
}

export enum ShoppingCartContextEnum {
  UNSET = -1,
  DETAILS,
  DETAILS_EDITING,
  CHECKOUT_PICKUP,
  CHECKOUT_DELIVERY,
}

export enum ShoppingCartFilterEnum {
  XPRESS,
  NON_SENDABLE,
}

export enum ShoppingCartActionTypeEnum {
  NO_ACTION = 0,
  ADD_TO_TEMPLATE = 1,
  MOVE_TO_ANOTHER_CART = 2,
}

export interface ShoppingCartDetailsState {
  context: ShoppingCartContextEnum
  filter: ShoppingCartFilterEnum
}

export enum MoveCartItemsOfferIdUpdateEnum {
  NoUpdates,
  ResetItemOfferIds,
  ResetItemOfferIdsAndUpdateTargetOfferId,
}

export enum CartVerificationErrorCode {
  AddressNotWithinDeliveryArea = 'AddressNotWithinDeliveryArea',
  ContainsProductsNotForSale = 'ContainsProductsNotForSail',
  CoreApiError = 'CoreApiError',
  CreditLimitExceeded = 'CreditLimitExceeded',
  DeliveryOptionNotPossible = 'DeliveryOptionNotPossible',
  ExpressOrderDeadlineExceeded = 'ExpressOrderDeadlineExceeded',
  ExpressOutOfStock = 'ExpressOutOfStock',
  OrderingBlocked = 'OrderingBlocked',
  OnlyPickupPossible = 'OnlyPickupPossible',
  PickupOptionNotPossible = 'PickupOptionNotPossible',
  ShippingBlocked = 'ShippingBlocked',
  ShippingDateNotPossible = 'ShippingDateNotPossible',
  ShippingDateNotPossibleAnymore = 'ShippingDateNotPossibleAnymore',
  UserNotPermitted = 'UserNotPermitted',
  UserIsCashCustomer = 'UserIsCashCustomer',
  Unset = 'Unset',
  WrongPhoneNumber = 'WrongPhoneNumber',
  CartWithWarehouseArticlesOutOfStock = 'CartWithWarehouseArticlesOutOfStock',
}

export enum OrderButtonActionType {
  Notify = 'Notify',
  Submit = 'Submit',
}

export enum OrderStateAfterSubmit {
  Pending = 'Pending',
  Success = 'Success',
  Error = 'Error',
  Unset = 'Unset',
}

export interface OrderSuccessResponsePayload {
  cartId: string
  date: string
  offerId: string
  orderId: string
  pickupNumber: string
  shopPromotionEmail?: string
  shopPromotionId?: string
  time: string
  shippingType: ShippingType
  storeName: string
}

export interface VerifyOfferInShoppingCartResult {
  removedRelations: string[]
  removedCartItems: string[]
  addedRelations: string[]
}
