import Fuse, { Expression, IFuseOptions } from 'fuse.js'

const defaultOptions = <T>(): IFuseOptions<T> => {
  return {
    includeScore: true,
    minMatchCharLength: 2,
    threshold: 0.2,
    distance: 200,
    ignoreLocation: true,
  }
}

export const useLocalSearchControlled = () => {
  /**
   * Uses "searchText" to find entities in "entities" array.
   * "searchText" is compared with properties values in entity.
   * "keys" array defines what properties must be compared with "searchText"
   * @param entities Entities
   * @param searchText Search text
   * @param keys Search keys
   * @param options FuseOptions
   * @returns
   */
  const search = <T>(
    entities: T[],
    searchText: string,
    keys: string[] = [],
    options?: IFuseOptions<T>
  ): T[] => {
    if (!searchText || searchText.length < 2) return entities

    const index = Fuse.createIndex(keys, entities)
    const result = new Fuse(
      entities,
      {
        keys,
        ...defaultOptions(),
        ...options,
      },
      index
    ).search({
      $and: searchText
        .split(' ')
        .filter((el) => {
          return !!el
        })
        .map((token) => {
          const orFields: Expression[] = keys.map((key) => ({ [key]: token }))
          return {
            $or: orFields,
          }
        }),
    })

    return result.map((fuseItem) => {
      return fuseItem.item
    })
  }

  return { search }
}
