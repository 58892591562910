import { useCallback } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { CustomerCompanyDataModelInput, ResponseMessage } from '@obeta/schema'
import { trackCustom } from '@obeta/utils/lib/tracking'

type SaveDataSheetCustomerCompanyDataResponse = {
  saveDataSheetCustomerCompanyData: ResponseMessage
}

const ResponseTypes = {
  Success: 'Success',
  Error: 'Error',
} as const

const SAVE_DATA_SHEET_CUSTOMER_COMPANY_DATA_MUTATION = gql`
  mutation SaveDataSheetCustomerCompanyData($input: CustomerCompanyDataModelInput!) {
    saveDataSheetCustomerCompanyData(input: $input) {
      type
      message
    }
  }
`

export const useSaveDataSheetCustomerCompanyData = () => {
  const client = useApolloClient()

  const updateDataSheetCustomerCompanyData = useCallback(
    async (input: CustomerCompanyDataModelInput): Promise<boolean> => {
      try {
        const response = await client.mutate<SaveDataSheetCustomerCompanyDataResponse>({
          mutation: SAVE_DATA_SHEET_CUSTOMER_COMPANY_DATA_MUTATION,
          variables: { input },
        })
        const data = response.data?.saveDataSheetCustomerCompanyData

        if (data?.type === ResponseTypes.Success) {
          return true
        } else {
          const errorMessage = data?.message || 'Unknown error'
          trackCustom(errorMessage)
          return false
        }
      } catch (error) {
        console.error('Error updating customer company data:', error)
        trackCustom('updating-customer-company-data-error')
        return false
      }
    },
    [client]
  )

  return updateDataSheetCustomerCompanyData
}
