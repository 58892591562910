import { AxiosError } from 'axios'
import { datadogRum } from '@datadog/browser-rum'

export const handleError = (error: Error | AxiosError) => {
  if ((error as AxiosError).response) {
    const axiosError = error as AxiosError

    datadogRum.addError(axiosError, {
      type: 'request',
      'request.status': `${axiosError.response?.status}`,
      'request.cause': 'backend',
      'request.url': axiosError.config?.url || '',
      url: axiosError.config?.url,
      data: axiosError.response?.data,
    })
  } else if ((error as AxiosError).request) {
    const axiosError = error as AxiosError

    datadogRum.addError(axiosError, {
      type: 'request',
      'request.cause': 'network',
      'request.url': axiosError.config?.url || '',
      url: axiosError.config?.url,
    })
  } else {
    datadogRum.addError(error)
  }
}
