import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material'
import { ReactComponent as CheckIcon } from 'assets/icon/designsystem/check.svg'
import { ReactComponent as UndoIcon } from 'assets/icon/designsystem/undo.svg'
import styles from './Confirm.module.scss'
import { SecondaryButton } from '../custom-button/CustomButton'
import { useTranslation } from 'react-i18next'

interface Props {
  heading: string
  body: string
  handleConfirm: () => void
  handleUndo: () => void
  openConfirmDialog: boolean
}

export const ConfirmWithUndo: React.FC<Props> = (props) => {
  const { body, heading, handleConfirm, handleUndo, openConfirmDialog } = props
  const { t } = useTranslation()

  return (
    <Dialog
      className={styles.dialog}
      open={openConfirmDialog}
      onClose={handleUndo}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={styles.dialogTitle}>
        {heading}
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Typography variant={'body'} color={'secondary'}>
          {body}
        </Typography>
      </DialogContent>
      <Divider variant="middle" sx={{ mx: 1 }} />
      <DialogActions className={styles.dialogActions}>
        <SecondaryButton fullWidth={false} leftIcon={<UndoIcon />} onClick={handleUndo}>
          {t('SHOPPING_CART.ACTIONS.UNDO')}
        </SecondaryButton>
        <SecondaryButton
          fullWidth={false}
          iconColor={'success'}
          leftIcon={<CheckIcon />}
          onClick={handleConfirm}
          autoFocus
        >
          {t('SHOPPING_CART.ACTIONS.CONFIRM')}
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  )
}
